import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';
import {useMutation} from "react-query";
import {UrlEnum} from "../enums/Url.enum";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {getHeaders} from "../shared/headers";
import Stack from "@mui/material/Stack";

interface Props {
}

const postSubmission = async (text: string, captcha: string) => {
    const response = await fetch(UrlEnum.SERVER_URL + UrlEnum.SUBMISSIONS, {
        method: 'POST',
        headers: getHeaders(captcha),
        body: JSON.stringify({ text, 'RecaptchaToken': captcha }),
    });

    console.log(response, 'response');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};


// Use the mutation in the component
const SubmissionForm: React.FC<Props> = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [text, setText] = useState('');
    const mutation = useMutation(({ text, captcha }: { text: string; captcha: string }) => postSubmission(text, captcha));

    const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
    const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
    const [captcha, setCaptcha] = useState('');

    const handleAddSubmission = async (text: string) => {
        if (parseInt(captcha) === num1 + num2) {
            if (executeRecaptcha) {
                const token = await executeRecaptcha("homepage");
                mutation.mutate({ text, captcha: token });
                //console.log(`Submitting: ${text}`);
            }
        } else {
            alert('Incorrect result for math problem. Please try again.');
            // Generate new numbers for the captcha
            setNum1(Math.floor(Math.random() * 10));
            setNum2(Math.floor(Math.random() * 10));
        }
    };


    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        handleAddSubmission(text);
        setText(''); // Reset input after submission
    };

    return (
        <Box component="form" onSubmit={handleSubmit} style={{marginBottom: 10}}>
            <TextField
                label="Jailbreak prompt to add"
                variant="outlined"
                fullWidth
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <Stack direction="row" spacing={2} style={{marginTop: 10}}>
            <TextField
                label={`To submit, answer what is ${num1} + ${num2}?`}
                variant="outlined"
                style={{ width: 'auto', minWidth: '300px' }}
                value={captcha}
                onChange={(e) => setCaptcha(e.target.value)}
            />
            <Button type="submit" variant="contained" color="primary" >
                Submit
            </Button>
            </Stack>
        </Box>

    );
};

export default SubmissionForm;
