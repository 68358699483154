export function getHeaders(captcha?: string) {
    const headers: HeadersInit = {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(`Azure:5Y72ikTt9CcGBPZ`)
    };
    if (captcha) {
        headers['Recaptcha-Token'] = captcha;
    }
    return headers;
}
