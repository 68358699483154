import React, {useState} from 'react';
import {Button, Box, Typography, IconButton} from '@mui/material';
import {useQuery} from "react-query";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import ForwardIcon from '@mui/icons-material/Forward';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Stack from '@mui/material/Stack';
import {UrlEnum} from "../enums/Url.enum";
import {getHeaders} from "../shared/headers";

interface Submission {
    id: string;
    text: string;
    upvotes: number;
    createdAt: string;
}

interface IVoteData {
    submissionId: string | null;
    voteType: string | null;
}

const fetchSubmissions = async () => {
    const response = await fetch(UrlEnum.SERVER_URL + UrlEnum.SUBMISSIONS, {
        method: 'GET',
        headers: getHeaders(),
    });

    if (!response.ok) throw new Error('Network response was not ok');
    return response.json();
};

const vote = async (id: string, captcha: string, url: string): Promise<Submission> => {
    const response = await fetch(url, {
        method: 'PATCH',
        headers: getHeaders(captcha),
        body: JSON.stringify({id}),
    });
    console.log(response, 'response');
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json();
};

const SubmissionList: React.FC = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [isTextExpanded, setTextExpanded] = useState<Record<string, boolean>>({});
    const [voteData, setVoteData] = useState<IVoteData[]>(() => {
        const data = localStorage.getItem('voteData');
        return data ? JSON.parse(data) : [];
    });
    const {data: submissions, isLoading, error} = useQuery<Submission[], Error>('submissions', fetchSubmissions);

    const isDownvoted = (id: string) => {
        return voteData.some(vote => vote.submissionId === id && vote.voteType === 'downvote');
    };

    const isUpvoted = (id: string) => {
        return voteData.some(vote => vote.submissionId === id && vote.voteType === 'upvote');
    };
    const handleVote = async (id: string, type: 'upvote' | 'downvote') => {
        let token = '';
        if (executeRecaptcha) {
            token = await executeRecaptcha("vote_action");
        }

        // Determine URLs for voting actions
        const upvoteUrl = UrlEnum.SERVER_URL + UrlEnum.SUBMISSIONS + "/" + id + UrlEnum.UPVOTE;
        const downvoteUrl = UrlEnum.SERVER_URL + UrlEnum.SUBMISSIONS + "/" + id + UrlEnum.DOWNVOTE;
        let url = type === 'upvote' ? upvoteUrl : downvoteUrl;

        const alreadyVoted = voteData.find(vote => vote.submissionId === id);

        if (!alreadyVoted) {
            // First time voting
            const newVoteData = [...voteData, {submissionId: id, voteType: type}];
            setVoteData(newVoteData);
            localStorage.setItem('voteData', JSON.stringify(newVoteData));
        } else if (alreadyVoted.voteType === type) {
            // Undo the vote
            const newVoteData = voteData.filter(vote => vote.submissionId !== id);
            setVoteData(newVoteData);
            localStorage.setItem('voteData', JSON.stringify(newVoteData));
            // Update URL based on the vote type
            url = type === 'upvote' ? downvoteUrl : upvoteUrl;
        } else {
            // Change the vote type (from upvote to downvote or vice versa)
            // This section might require specific logic based on how your backend handles vote changes.
            // For simplicity, it's handled as first removing the vote and then adding a new vote of the opposite type.
            const filteredVoteData = voteData.filter(vote => vote.submissionId !== id);
            const newVoteData = [...filteredVoteData, {submissionId: id, voteType: type}];
            setVoteData(newVoteData);
            localStorage.setItem('voteData', JSON.stringify(newVoteData));
            // Update URL based on new vote type
            url = type === 'upvote' ? upvoteUrl : downvoteUrl;
        }

        // Perform the voting action
        await vote(id, token, url);
        // Refresh the submissions list
        await fetchSubmissions();

    };


    const handleTextExpanded = (id: string) => setTextExpanded(prevState => ({...prevState, [id]: !prevState[id]}));

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>An error occurred: {error.message}</div>;

    return (
        <Box>
            {submissions?.map((submission) => (
                <Stack key={submission.id} spacing={2}
                       sx={{mb: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px'}}>
                    <Stack direction="row" spacing={2} alignItems="top">
                        <Stack direction="column" spacing={2} alignItems="center">
                            <IconButton
                                onClick={() => handleVote(submission.id,  'upvote')}
                                style={{
                                    transform: 'rotate(-90deg)',
                                    color: isUpvoted(submission.id) ? 'orangered' : 'inherit'
                                }}>
                                {isUpvoted(submission.id) ? <ForwardIcon/> : <ForwardOutlinedIcon/>}
                            </IconButton>
                            <Typography variant="body1">{submission.upvotes}</Typography>
                            <IconButton
                                onClick={() => handleVote(submission.id,  'downvote')}
                                style={{
                                    transform: 'rotate(90deg)',
                                    color: isDownvoted(submission.id) ? 'orangered' : 'inherit'
                                }}>
                                {isDownvoted(submission.id) ? <ForwardIcon/> : <ForwardOutlinedIcon/>}
                            </IconButton>
                        </Stack>
                        <Box sx={{
                            ml: 20,
                            position: 'relative',
                            maxHeight: isTextExpanded[submission.id] ? 'none' : '150px',
                            overflow: 'hidden'
                        }}>
                            <Typography variant="body1" sx={{
                                overflowWrap: 'break-word',
                                wordBreak: 'break-all'
                            }}>{submission.text}</Typography>
                            {!isTextExpanded[submission.id] && (
                                <Box sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    height: '20px',
                                    backgroundImage: 'linear-gradient(to top, #fff, transparent)'
                                }}/>
                            )}
                        </Box>
                    </Stack>
                    <Button onClick={() => handleTextExpanded(submission.id)}>
                        {isTextExpanded[submission.id] ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
                    </Button>
                </Stack>

            ))}
        </Box>
    );
};

export default SubmissionList;
