import React from 'react';
import { Container } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import SubmissionForm from './components/SubmissionForm';
import SubmissionList from './components/SubmissionList';


const App: React.FC = () => {
  return (
      <GoogleReCaptchaProvider reCaptchaKey="6LczIaApAAAAAJJ-Cy0R-lpKAB-Jmp-kY8T18BfD">
          <Container>
              <h1>Jailbreak Chat</h1>
              <SubmissionForm />
              <SubmissionList />
          </Container>
      </GoogleReCaptchaProvider>
  );
};

export default App;
